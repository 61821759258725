import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useRef } from 'react';
import { Analytics } from '@vercel/analytics/react';

function App() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
    window.addEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {

  };

  const redirectToTelegram = () => {
    window.location.href = 'https://t.me/addlist/fkDk1NzXD0QyYTEx';
  };

  return (
    <div className="App">

      <header className="header">
        <div className="row">
          <div className="spacer"></div>
          <h1 className='name'>The Offical Trans <br/>
          Nikki minajj!!</h1>
          <div className="header_button">
            <button className="onlyfans_button"  onClick={() => window.location.href='https://onlyfans.com/nickimbaby'}>JOIN HERE</button>
          </div>
        </div>

        <div className="title">
          <div className="status"> 🟢Free LIVE SHOWS DAILY  </div>
          <h1 className="main_text">🚨 Trans. BET you didn’t know <br /> <p className="main_text_color">Im waiting 👇</p></h1>
          <button className="onlyfans_button join"  onClick={() => window.location.href='https://onlyfans.com/nickimbaby'}>JOIN HERE</button>
        </div>

      </header>


      <div className="percs">
        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Daily Free Pics & Videos
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Instant Access
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Sexcam
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Chat with me
          </div>
        </div>
      </div>

      <div className="gallery">
        <div className="status"> SELFIES & MUCH MORE! </div>
        <h1 className="gallery_text">Want accesss to my <br /> <p className="gallery_text_color">phone's pics & vids?</p></h1>

        <div className="gallery_images">
          <div className="float_container" data-aos="slide-right">
            <div className="gallery_image" data-aos="slide-up"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-left">
            <div className="gallery_image" data-aos="slide-up"></div>
          </div>
        </div>

      </div>

      <div className='flip_images'>

        <div className="flip_container" data-aos="slide-up">
          <div className="flip_card card_left">
            <div className="flip_card_front"></div>
            <div className="flip_card_back"></div>
          </div>
          <div className='flip_div'>
            <h3 className="flip_title">Talk To Me In The DM’s</h3>
            <p className="flip_text">My OF is the only place where I reply to every message I receive 🙂</p>
            <button className='onlyfans_button flip_button' onClick={() => window.location.href='https://onlyfans.com/nickimbaby'}>JOIN HERE</button>
          </div>
        </div>

        <div className="flip_container" data-aos="slide-up">
          <div className="flip_card card_right">
            <div className="flip_card_front"></div>
            <div className="flip_card_back"></div>
          </div>
          <div className='flip_div'>
            <h3 className="flip_title">Collabs With The Sexiest Creators!</h3>
            <p className="flip_text">I love creating sexy and fun content including collabs with the biggest creators on Telegram!</p>
            <button className='onlyfans_button flip_button' onClick={() => window.location.href='https://onlyfans.com/nickimbaby'}>JOIN HERE</button>
          </div>
        </div>

      </div>


      <div className='reviews gallery'>
        <div className="status"> 🟢Free LIVE SHOWS DAILY </div>
        <h1 className="review_text gallery_text">And Free 1 Hour Sex Tape on Arrival    <br /> <p className="gallery_text_color"> from me! </p></h1>



        <img 
  className='review_image' 
  src='https://i.ibb.co/JzR0XbT/photo-2024-10-02-03-37-31.jpg' 
  alt='Review 1' 
  style={{ width: '100%', height: 'auto', maxWidth: '1000px' }} 
/>

<a 
  className='onlyfans_button flip_button' 
  href='https://onlyfans.com/tsnikkiminaj' 
  style={{ padding: '20px 40px', fontSize: '20px', width: 'auto', height: 'auto', whiteSpace: 'nowrap', margin: '10px 0', display: 'inline-block', textDecoration: 'none' }}>
  JOIN MY LIVE SHOWS HERE FOR FREE
</a>





      </div>

      <div className='action'>
        <div className='waves'>
          <div className='wave1'>
            <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
              <path fill="#ea0aaa" d="M0 0L2600 0 2600 69.1 0 0z"></path>
              <path fill="#ea0aaa" style={{ opacity: "0.5" }} d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
              <path fill="#ea0aaa" style={{ opacity: "0.25" }} d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
          </div>
          <div className='wave2'>
            <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
              <path fill="#ea0aaa" style={{ opacity: "0.3" }} d="M0 0L2600 0 2600 69.1 0 0z"></path>
              <path fill="#ea0aaa" style={{ opacity: "0.5" }} d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
              <path fill="#ea0aaa" style={{ opacity: "0.25" }} d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
          </div>
        </div>
        <div className='action_container'>
          <div className='action_box'>
            <div className='action_square'>
              <div className='action_image'> </div>
            </div>
            <div className='action_tex'>
              <h1 className="action_title gallery_text"> Join now <br /> Stop Thinking! <br /> <p className="action_text_color"> Come & Say Hi! </p></h1>
              <button className='onlyfans_button flip_button' onClick={() => window.location.href='https://onlyfans.com/nickimbaby'} >JOIN HERE</button>
            </div>
          </div>

        </div>
      </div>

      <div className='footer'>
        <h1 className='name'>The Offical Trans <br/>
        Nikki minajj!!</h1>
      </div>
      <Analytics />
    </div>
  );
}

export default App;
